<template>
  <div class="footer flex-b">
    <div class="f-left">
      <div class="logo-img"><img src="@/assets/img/btnlogo.png" /></div>
      <div class="f-info">电话：021-65289166</div>
      <div class="f-info">地址：上海市闵行区申旺路588号</div>
    </div>
    <div class="f-right">
      <ul class="f-list">
        <li><router-link to="/about">走进金钟</router-link></li>
        <li><router-link to="/product">产品中心</router-link></li>
        <li><router-link to="/contact">联系我们</router-link></li>
      </ul>
      <div class="licence">© 上海医疗器械（集团）有限公司手术器械厂 互联网药品信息服务资格证书:(沪)-非营业性-2010-0088 沪ICP备10211167号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  width: 100%;
  height: 264px;
  padding: 0 96px 61px;
  align-items: flex-end;
  font-weight: 500;
  color: #FFFFFF;
  background: #373737;
  .f-left {
    text-align: left;
    .logo-img {
      width: 253px;
      height: 28px;
      margin-bottom: 37px;
    }
    .f-info {
      font-size: 18px;
      line-height: 25px;
      margin-top: 8px;
    }
  }
  .f-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .f-list {
      display: flex;
      align-items: flex-end;
      li {
        margin-left: 72px;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .licence {
      margin-top: 21px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1550px) {
  .footer {
    height: 223px;
    padding: 0 81px 52px;
    .f-left {
    text-align: left;
    .logo-img {
      margin-bottom: 32px;
    }
    .f-info {
      font-size: 15px;
    }
  }
  .f-right {
    .f-list {
      li {
        margin-left: 60px;
        font-size: 17px;
      }
    }
    .licence {
      font-size: 12px;
    }
  }
  }
}

@media screen and (max-width: 750px) {
  .f-right {
    display: none !important;
  }
}
</style>
