<template>
  <div class="header" :style="containerStyle" @mouseenter="showAbout" @mouseleave="hideAbout">
    <nav class="main-nav flex-b" :class="{'aboutForStatic': isAbout == true }">
      <div class="logo-img">
        <router-link to="/">
          <img v-show="!isAbout" src="@/assets/img/logo.png" />
          <img v-show="isAbout" src="@/assets/img/btnlogo.png" />
        </router-link>
      </div>
      <ul class="nav-list">
        <li :class="{'active': from == 'Home' }">
          <router-link class="list-item" to="/">首页</router-link>
        </li>
        <li :class="{'active': from == 'About', 'activeForStatic': isAbout == true }">
          <router-link class="list-item" to="/about">走进金钟</router-link>
        </li>
        <li :class="{'active': from == 'Product' }" @mouseleave="hideMenu">
          <div class="list-item" @mouseenter="showMenu">
            <router-link class="product-link" to="/product">产品中心</router-link>
          </div>
          <el-collapse-transition>
            <div class="product-list" v-show="isMenuVisible">
              <div class="transition-box">
                <div class="category-item" v-for="item in categoryList" :key="item.firstCode">
                  <div class="product-link" @click="goPro(item)">
                    {{item.firstName}}
                  </div>
                  <!-- <router-link class="product-link" :to="{ path: '/product', query: { id: item.secondList[0].secondCode, parentId: item.firstCode } }">{{item.firstName}}/{{item.secondList[0].secondCode}}</router-link> -->
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </li>
        <li :class="{'active': from == 'News' }">
          <router-link class="list-item" to="/news">新闻中心</router-link>
        </li>
        <li :class="{'active': from == 'Contact' }">
          <router-link class="list-item" to="/contact">联系我们</router-link>
        </li>
        <li :class="{'active': from == 'Join' }">
          <router-link class="list-item" to="/join">加入我们</router-link>
        </li>
      </ul>
      <div class="nav-title">
        <!-- <div class="title-phone">
          <img v-show="!isAbout" src="@/assets/img/phone.png" />
          <img v-show="isAbout" src="@/assets/img/phone_write.png" />
          <span>400-178-5858</span>
        </div> -->
        <div class="title-cloud list-item">
          <img v-show="!isAbout" src="@/assets/img/cloud.png" />
          <img v-show="isAbout" src="@/assets/img/cloud_write.png" />
          <span @click="toCloud">金钟云平台</span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      categoryList: {
        type: Array,
        default: () => {
          return []
        }
      },
      from: {
        type: String,
        default: () => {
          return ''
        }
      }
    },
    data() {
      return {
        isMenuVisible: false,
        isAbout: false,
        isHover: false,
        scrollTop: 0
      }
    },
    computed: {
      containerStyle() {
        // 计算样式对象，根据具体需求可调整计算公式
        return {
          backgroundColor: `rgba(255,255,255,${this.scrollTop / 100})`
        }
      }
    },
    mounted() {
      this.isAbout = this.from === 'About'
      // 监听滚动事件，更新滚动高度
      window.addEventListener('scroll', () => {
        this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (this.from === 'About' && !this.isHover) {
          this.isAbout = this.scrollTop < 50
        }
      })
    },
    methods: {
      goPro(item) {
        console.log(item, 1)
        this.$router.push({
          path: '/product',
          query: {
            id: item.secondList[0].secondCode,
            parentId: item.firstCode
          }
        })
      },
      toCloud() {
        window.open('https://www.jzsf.com/jzCloudHome.jsp', '_blank')
      },
      // 移入
      showMenu() {
        this.isMenuVisible = true
      },
      // 移出
      hideMenu() {
        this.isMenuVisible = false
      },
      showAbout() {
        this.isHover = true
        if (this.from === 'About') this.isAbout = false
      },
      hideAbout() {
        this.isHover = false
        if (this.from === 'About' && this.scrollTop < 50) this.isAbout = true
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  // 主题色
  @primary-color: var(--primary-color);
  @bg-color: var(--bg-color);

  // About 静态特有颜色
  .aboutForStatic {
    color: #7CA9D6 !important;
  }

  .active {
    color: @primary-color;
    font-weight: 600;
  }

  .activeForStatic {
    color: #DDE7F2 !important;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    z-index: 100;

    &:hover {
      background-color: #fff !important;
    }

    .main-nav {
      width: 1420px;
      height: 100%;
      margin: auto;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      color: #343434;

      .logo-img {
        width: 218px;
        height: 24px;
      }

      .nav-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        li {
          height: 100%;
          display: flex;
          align-items: center;
        }

        .product-link {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .product-list {
          position: fixed;
          top: 72px;
          left: 0;
          width: 100%;
          height: auto;
          border-top: 1px solid #EBEBEB;
          background-color: #fff;
          z-index: 30000;

          .transition-box {
            position: relative;
            width: 1420px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: auto;
            padding: 40px 0;
            border-radius: 4px;
            box-sizing: border-box;
            z-index: 300;

            .category-item {
              width: 226px;
              margin-right: 10px;
              margin-bottom: 15px;
              padding: 8px 0;
              text-align: center;
              background-color: @bg-color;

              &:hover {
                color: #fff;
                background-color: @primary-color;
              }
            }
          }
        }
      }

      .nav-title {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          display: inline-block;
        }

        .title-phone {
          padding: 8px 16px;
          font-size: 18px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          vertical-align: middle;
          margin-bottom: 4px;
        }
      }

      .list-item {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          font-weight: 600;
          color: @primary-color;
          background-color: @bg-color;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

  }

  @media screen and (max-width: 1550px) {
    .header {
      height: 60px;

      .main-nav {
        width: 1200px;

        .nav-list {
          .product-list {
            top: 60px;

            .transition-box {
              width: 1200px;

              .category-item {
                width: 190px;
              }
            }
          }
        }

      }
    }
  }

  @media screen and (max-width: 750px) {
    .header {
      height: 60px;
      font-size: 10px;

      .main-nav {
        width: 100vw;
        display: flex;

        .logo-img {
          width: 100px;
          height: 13px;
          margin-bottom: 10px;
        }

        .nav-list {
          .list-item {
            padding: 2px;
            font-size: 10px;
          }

          .product-list {
            font-size: 10px;
            top: 60px;

            .transition-box {
              font-size: 10px;
              width: 100vw;

              .category-item {
                font-size: 10px;
                width: 10vw;
              }
            }
          }
        }

        .nav-title {
          display: none;
          font-size: 10px;
          .title-phone {
            font-size: 10px;
          }
        }

      }
    }
  }
</style>
