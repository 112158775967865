import request from '@/utils/request'

/**
 * 字典项列表查询
 */
export function getFirstAndSecondCategoryUrl(params) {
  return request({
    url: '/cloud/product/getFirstAndSecondCategory',
    method: 'get',
    params
  })
}

/**
 * 搜索框
 */
export function getSearch(params) {
  return request({
    url: '/cloud/solr/productJZ',
    method: 'get',
    params
  })
}

/**
 * 点击左侧菜单
 */
export function getItemList(data) {
  return request({
    url: '/cloud/product/getProductListByCategoryCodeJZ',
    method: 'post',
    data: data
  })
}
