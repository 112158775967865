import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: { title: '首页' }
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
	},
	{
		path: '/product',
		name: 'Product',
		component: () => import(/* webpackChunkName: "product" */ '../views/product/Product.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "contact" */ '../views/contact/Contact.vue')
	},
	{
		path: '/news',
		name: 'News',
		component: () => import(/* webpackChunkName: "news" */ '../views/news/News.vue')
	},
	{
		path: '/join',
		name: 'Join',
		component: () => import(/* webpackChunkName: "join" */ '../views/join/Join.vue')
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageXOffset = 0
	next()
})

export default router
