import axios from 'axios'
import {
  Message
} from 'element-ui'

// import { tansParams } from './index'
// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API_BASE_URL, // url = base url + request url
  withCredentials: false, // true:send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  response => {
    /* 以流的形式返回时，没有状态码 */
    // 判断返回的是不是文件流，content-type根据具体的返回值判断
    const headers = response.headers
    if (headers['content-type'] === 'application/octet-stream') {
      return response
    }
    return response

    // if the custom code is not 20000, it is judged as an error.
    // const res = response.data
    // if (res.code !== 2000) {
    // 	if (White.includes(response.config.url)) {
    // 		return res
    // 	}

    // 	Message({
    // 		message: res.msg || 'Error',
    // 		type: 'error',
    // 		duration: 5 * 1000
    // 	})

    // 	// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // 	if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    // 		// to re-login
    // 		MessageBox.confirm(
    // 			'You have been logged out, you can cancel to stay on this page, or log in again',
    // 			'Confirm logout',
    // 			{
    // 				confirmButtonText: 'Re-Login',
    // 				cancelButtonText: 'Cancel',
    // 				type: 'warning'
    // 			}
    // 		).then(() => {
    // 			store.dispatch('user/logout')
    // 		})
    // 	}

    // 	return Promise.reject(new Error(res.msg || 'Error'))
    // } else {
    // 	return res
    // }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
