<template>
	<div class="home">
		<Header :category-list="categoryList" :from="name" />
		<div class="container">
			<div class="banner">
				<video style="width: 100%" src="@/assets/img/banner/normalvideo.mp4" autoplay loop muted>
					<!-- 如果浏览器不支持video，可以提供一个备用文本 -->
					您的浏览器不支持HTML5视频。
				</video>
				<span class="mid-text">精于器.源于技</span>
				<!-- <img src="@/assets/img/banner.jpg" /> -->
			</div>
			<div class="source">
				<div class="source-box home-box" style="position: relative">
					<div id="rollBox1" :style="changeRoll1" style="position: relative">
						<h2 class="home-title">金钟牌手术器械, 源于1928年</h2>
						<p class="home-auxiliary">Jinzhong surgical instrument, originated in 1928</p>
					</div>
					<div id="rollBox2" class="source-list" :style="changeRoll2">
						<el-carousel
							trigger="click"
							class="carHeight"
							arrow="always"
							indicator-position="none"
							@change="changeBanner"
						>
							<el-carousel-item v-for="item in carouselList" :key="item.year">
								<div class="banner-item flex-b">
									<div class="banner-img"><img :src="item.imgUrl" /></div>
									<div class="banner-title">
										<h2>{{ item.year }}</h2>
										<p>{{ item.content }}</p>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
						<div class="source-scale"><img src="@/assets/img/sourcescale.png" /></div>
						<div class="source-year flex-b">
							<img class="source-img" :style="{ left: carouselIndexLeft + 'px' }" src="@/assets/img/scaleicon.png" />
							<span v-for="(year, index) in yearList" :key="'0' + year" :class="{ activeYear: index == carouselIndex }">
								{{ year }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="treatment">
				<div class="home-box">
					<h2 class="home-title">品种规格 14000+, 覆盖医疗全类型</h2>
					<p class="home-auxiliary">14000+varieties and specifications, covering all types of medical treatment</p>
					<div class="treatment-list" id="rollBox11" :style="changeRoll11">
						<template v-for="(item, index) in categoryList">
							<div
								:key="item.firstCode"
								v-if="index < 10"
								class="treatment-item"
								:class="{
									item3H: index == 0,
									item2H: index == 1 || index == 7,
									item1H: index == 2 || index == 3 || index == 4 || index == 5 || index == 6 || index == 8 || index == 9
								}"
							>
								<div class="item-title">
									<h4>{{ item.firstName }}</h4>
									<p v-for="itemp in item.secondList" :key="itemp.secondCode" @click="toProduct(itemp.secondCode)">
										{{ itemp.secondName }}
										<i class="el-icon-arrow-right"></i>
									</p>
									<p>
										更多
										<i class="el-icon-arrow-right"></i>
									</p>
								</div>
								<img :src="item.backgroudImage" class="item-img" />
							</div>
						</template>
						<div class="treatment-item item1H">
							<div class="item-title">
								<h4>更多品种</h4>
							</div>
							<img src="../assets/img/more_s.png" class="item-img" />
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="brand">
        <div class="brand-box home-box">
          <div id="rollBox3" :style="changeRoll3">
            <h2 class="home-title">品质铸就品牌</h2>
            <p class="home-auxiliary">Quality builds brand</p>
          </div>

          <div class="brand-list flex-b" style="position: relative;height: 648px;">
            <div id="rollBox4" :style="changeRoll4" class="box-card brand-box1" style="left: 0;">
              <div class="brand-title flex-b">
                <div class="brand-img1"><img src="@/assets/img/brand_1.png" /></div>
                <p class="brand-tit">企业使命</p>
              </div>
              <p class="brand-body">
                致力于人类的健康事业, 提供人性化、专业化、规模化的医疗产品和服务, 实现企业价值和履行社会责任。
              </p>
              <div class="bg-icon"><img src="@/assets/img/brandbg.png" /><img src="@/assets/img/brandbg.png" /></div>
            </div>
            <div id="rollBox5" :style="changeRoll5" class="box-card brand-box2" style="left: 35%;">
              <div class="brand-title flex-b">
                <div class="brand-img2"><img src="@/assets/img/brand_2.png" /></div>
                <p class="brand-tit">企业愿景</p>
              </div>
              <p class="brand-body">
                打造一个充分展现医疗技术发展最前沿成果的“金钟”专业品牌, 成为一个具有行业引领地位的中国手术器械集成制造企业和用户最有价值的合作伙伴。
              </p>
              <div class="bg-icon"><img src="@/assets/img/brandbg.png" /><img src="@/assets/img/brandbg.png" /></div>
            </div>
            <div id="rollBox6" :style="changeRoll6" class="box-card brand-box3" style="right: 0;">
              <div class="brand-title flex-b">
                <div class="brand-img3"><img src="@/assets/img/brand_3.png" /></div>
                <p class="brand-tit">企业理念</p>
              </div>
              <p class="brand-body">
                基于务实的敬业精神, 锐于创新的进取精神, 勇于争挑的担当精神, 善于协同的团队精神。
              </p>
              <div class="bg-icon"><img src="@/assets/img/brandbg.png" /><img src="@/assets/img/brandbg.png" /></div>
            </div>
          </div>
        </div>
      </div> -->
			<div class="users">
				<div class="users-box home-box">
					<div id="rollBox7" :style="changeRoll7">
						<h2 class="home-title">金钟用户, 遍布全球</h2>
						<p class="home-auxiliary">Jinzhong users all over the world</p>
					</div>
					<div class="users-list" id="rollBox8" :style="changeRoll8">
						<div class="users-body">
							<p class="users-title">
								<span class="usersNum">40+</span>
								个国家
							</p>
							<p class="users-info">出口国家地区：北美；南美；非洲；中东；东南亚，日本，蒙古，俄罗斯等</p>
						</div>
						<div class="users-body">
							<p class="users-title">
								<span class="usersNum">14000+</span>
								品种规格
							</p>
							<p class="users-info">金钟牌手术器械现有品种规格14000余种，几乎涵盖了所有的临床科目</p>
						</div>
						<div class="users-body">
							<p class="users-title">
								<span class="usersNum">1000万+</span>
								年产手术器械
							</p>
							<p class="users-info">年产逾千万件，是国内规模最大、品种最多、历史最悠久的手术器械品牌</p>
						</div>
					</div>
					<div id="rollBox8" :style="changeRoll8" class="users-bg"><img src="@/assets/img/usersbg.png" /></div>
				</div>
			</div>
			<div class="contact">
				<div class="contact-box home-box">
					<div id="rollBox9" :style="changeRoll9">
						<h2 class="home-title">联系我们</h2>
						<p class="home-auxiliary">Contact us</p>
					</div>

					<div class="contact-list flex-b" id="rollBox10" :style="changeRoll10">
						<div class="contact-address">
							<div class="address-img"><img src="@/assets/img/addressimg.jpg" /></div>
							<div class="address-info flex-b">
								<div class="left">
									<div class="left-body">
										<div class="left-img"><img src="@/assets/img/homeicon/addricon.png" /></div>
										<p>地址：上海市闵行区申旺路588号</p>
									</div>
									<div class="left-body">
										<div class="left-img"><img src="@/assets/img/homeicon/telicon.png" /></div>
										<p>电话：021-65289166</p>
									</div>
									<div class="left-body">
										<div class="left-img"><img src="@/assets/img/homeicon/postalicon.png" /></div>
										<p>邮政编码：201108</p>
									</div>
									<div class="left-body">
										<div class="left-img"><img src="@/assets/img/homeicon/emailicon.png" /></div>
										<p>Email：</p>
										<p class="primary-color"><a href="mailto:wmkx@jzsf.com">wmkx@jzsf.com</a></p>
									</div>
								</div>
								<div class="right">
									<div class="right-code"><img src="@/assets/img/wxcode.png" /></div>
									<p>扫码关注公众号</p>
								</div>
							</div>
						</div>
						<div class="contact-map"><img src="@/assets/img/map.png" /></div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import { getFirstAndSecondCategoryUrl } from '@/api/common'

	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				name: 'Home',
				categoryList: [],
				isclick: false,
				carouselList: [
					{
						year: 1928,
						content:
							'奚记作坊（上海手术器械十厂前身之一）开业。生产各种铜质手术器械如妇科器械、子宫镜、肛门镜等。 新美工艺社（上海手术器械十厂前身之一）开业。生产红十字袋、口罩、单架床、疝气带等。',
						imgUrl: require('@/assets/img/banner/banner_1928.png')
					},
					{
						year: 1931,
						content:
							'懋昌医疗器械工场（上海手术器械六厂前身）开业，厂址在至浦东东昌路，生产场地50~60平方米，人员7~8人。生产持针钳、止血钳、牛痘刀等。开始使用“414金钟牌”商标。',
						imgUrl: require('@/assets/img/banner/banner_1931.png')
					},
					{
						year: 1971,
						content:
							'上海手术器械三厂、上海手术器械四厂和上海医械锻坯厂合并，成立上海手术器械厂。由国家投资140万元，厂址在粤秀路351号，建筑面积10339平方米。',
						imgUrl: require('@/assets/img/banner/banner_1971.png')
					},
					{
						year: 1979,
						content: '上海手术器械厂使用“磁极”牌商标。上海手术器械六厂正式注册“金钟”商标。',
						imgUrl: require('@/assets/img/banner/banner_1979.png')
					},
					{
						year: 1998,
						content: '上海手术器械六厂并入上海手术器械厂。“金钟”牌与“磁极”牌商标并用。',
						imgUrl: require('@/assets/img/banner/banner_1998.png')
					},
					{
						year: 2001,
						content: '上海手术器械厂更名上海医疗器械（集团）有限公司手术器械厂。全产线产品统一使用“金钟”牌商标。',
						imgUrl: require('@/assets/img/banner/banner_2001.png')
					},
					{
						year: 2010,
						content:
							'上海医疗器械（集团）有限公司手术器械厂注册“金钟”衍生品牌“JZ CLASSIC”。战略目标调整为技术改造和产品升级，对生产设施作了大幅改造，从普通设备过渡到数控设备，从产品改进项目到实施科研项目，产品总数从4000多种扩大到8000多种，计算机在管理上逐步展开应用。',
						imgUrl: require('@/assets/img/banner/banner_2010.png')
					},
					{
						year: 2015,
						content:
							'鱼跃医疗全资收购上海医疗器械（集团）有限公司，上海医疗器械（集团）有限公司手术器械厂企业转制成功，从原有国企脱离，并入鱼跃医疗上市公司平台。',
						imgUrl: require('@/assets/img/banner/banner_2015.png')
					},
					{
						year: 2016,
						content:
							'上手金钟手术器械江苏有限公司成立，同时金钟德国研发中心正式挂牌。金钟“极”系列产品正式上线。采用国产临床研发、德国机加工生产的方式引入先进的德国工艺与技术。让民族手术器械品牌再次扬帆起航。',
						imgUrl: require('@/assets/img/banner/banner_2016.png')
					},
					{
						year: 2023,
						content:
							'搬迁至闵行区申旺路588号新厂区，同时通过组织架构调整、重点产品发展、生产能力建设等方面，逐步扩大和巩固市场占有率，逐步打造企业核心竞争力。',
						imgUrl: require('@/assets/img/banner/banner_2023.png')
					}
				],
				carouselIndex: 0,
				carouselIndexLeft: 0,
				carouselCardinal: 20,
				yearList: [1928, 1931, 1971, 1979, 1998, 2001, 2010, 2015, 2016, 2023],
				percent1: 0,
				percent2: 0,
				percent3: 0,
				percent4: 0,
				percent5: 0,
				percent6: 0,
				percent7: 0,
				percent8: 0,
				percent9: 0,
				percent10: 0,
				percent11: 0
			}
		},
		watch: {
			// eslint-disable-next-line quote-props
			$route: {
				handle(to, from) {
					document.body.scrollTop = 0
					document.documentElement.scrollTop = 0
				}
			}
		},
		computed: {
			changeRoll1() {
				const per = this.percent1
				// let boxTop1 = 200 - 200 * per1
				// if (boxTop1 < 0) {
				//   boxTop1 = 0
				// }
				return {
					opacity: `${per}`
					// top: boxTop1 + 'px',
				}
			},
			changeRoll2() {
				const per = this.percent2
				return {
					opacity: `${per}`
				}
			},
			changeRoll3() {
				const per = this.percent3
				return {
					opacity: `${per}`
				}
			},
			changeRoll4() {
				const per = this.percent4
				let boxTop = 60 - 60 * per
				if (boxTop < 0) {
					boxTop = 0
				}
				boxTop = boxTop + 88
				return {
					opacity: `${per}`,
					top: boxTop + 'px'
				}
			},
			changeRoll5() {
				const per = this.percent5
				let boxTop = 60 - 60 * per
				if (boxTop < 0) {
					boxTop = 0
				}
				boxTop = boxTop + 88
				return {
					opacity: `${per}`,
					top: boxTop + 'px'
				}
			},
			changeRoll6() {
				const per = this.percent6
				let boxTop = 60 - 60 * per
				if (boxTop < 0) {
					boxTop = 0
				}
				boxTop = boxTop + 88
				return {
					opacity: `${per}`,
					top: boxTop + 'px'
				}
			},
			changeRoll7() {
				const per = this.percent7
				return {
					opacity: `${per}`
				}
			},
			changeRoll8() {
				const per = this.percent8
				return {
					opacity: `${per}`
				}
			},
			changeRoll9() {
				const per = this.percent9
				return {
					opacity: `${per}`
				}
			},
			changeRoll10() {
				const per = this.percent10
				return {
					opacity: `${per}`
				}
			},
			changeRoll11() {
				const per = this.percent11
				return {
					opacity: `${per}`
				}
			},
			carouselIndexLeftComputed() {
				return this.carouselCardinal + this.carouselIndex * (this.carouselCardinal === 20 ? 131.35 : 111)
			}
		},
		created() {
			this.getCategory()
			this.getWindowWidth()
			this.carouselIndexLeft = this.carouselIndexLeftComputed
		},
		mounted() {
			window.addEventListener('resize', this.handleResize)
			window.addEventListener('scroll', this.handleScroll)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize)
			window.removeEventListener('scroll', this.handleScroll)
		},
		methods: {
			getCategory() {
				getFirstAndSecondCategoryUrl().then(res => {
					this.categoryList = res.data.datas.map((item, index) => {
						let secondList = []
						if (index === 0 && item.secondList && item.secondList.length > 8) {
							secondList = item.secondList.slice(0, 8)
						} else if ((index === 1 || index === 7) && item.secondList && item.secondList.length > 5) {
							secondList = item.secondList.slice(0, 5)
						} else if ([2, 3, 4, 5, 6, 8, 9].includes(index) && item.secondList && item.secondList.length > 2) {
							secondList = item.secondList.slice(0, 2)
						}
						return {
							firstName: item.firstName,
							firstCode: item.firstCode,
							backgroudImage: item.backgroudImage,
							secondList
						}
					})
				})
			},
			toProduct(code) {
				this.$router.push({
					path: '/product',
					query: {
						id: code
					}
				})
			},
			handleResize() {
				this.getWindowWidth()
				this.carouselIndexLeft = this.carouselIndexLeftComputed
			},
			getWindowWidth() {
				this.carouselCardinal = window.innerWidth > 1550 ? 20 : 16
			},
			changeBanner(n, o) {
				this.carouselIndex = n
				this.carouselIndexLeft = this.carouselIndexLeftComputed
			},
			handleScroll() {
				// const rollBox1 = document.querySelector('#rollBox1');
				// const rect1 = rollBox1.getBoundingClientRect();
				// let calcNum1 = rect1.top / windowHeight
				// console.log(windowHeight, 'rect1')   // 902 固定值
				// if (calcNum1 > 0.618) {
				//   this.percent1 = 0
				// } else if (calcNum1 > 0.4) {
				//   this.percent1 = (windowHeight * (0.618 - calcNum1)) / 184
				// } else {
				//   this.percent1 = 1
				// }
				// if (calcNum2 > 0.618) {
				//   this.percent2 = 0
				// } else if (calcNum2 > 0.4) {
				//   this.percent2 = (windowHeight * (0.618 - calcNum2)) / 184
				// } else {
				//   this.percent2 = 1
				// }
				this.handleComputed('#rollBox1')
				this.handleComputed('#rollBox2')
				// this.handleComputed('#rollBox3')
				// this.handleComputed('#rollBox4')
				// this.handleComputed('#rollBox5')
				// this.handleComputed('#rollBox6')
				this.handleComputed('#rollBox7')
				this.handleComputed('#rollBox8')
				this.handleComputed('#rollBox9')
				this.handleComputed('#rollBox10')
				this.handleComputed('#rollBox11')
			},
			handleComputed(id, topNum = 0) {
				const str = 'percent' + id.slice(8, id.length)
				console.log(str)
				const rollBox = document.querySelector(id)
				const rect = rollBox.getBoundingClientRect()
				const windowHeight = window.innerHeight
				const calcNum = (rect.top + topNum) / windowHeight
				if (calcNum > 0.918) {
					this[str] = 0
				} else if (calcNum > 0.7) {
					this[str] = (windowHeight * (0.918 - calcNum)) / 184
				} else {
					this[str] = 1
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// 主题色
	@primary-color: var(--primary-color);
	@bg-color: var(--bg-color);

	.primary-color {
		color: @primary-color;
	}

	.activeYear {
		color: @primary-color;
	}

	.mid-text {
		z-index: 1000;
		letter-spacing: 60px;
		position: absolute;
		transform: translate(-50%, -50%);
		color: #fff;
		left: 50%;
		top: 50%;
		font-size: 28px;
	}

	.home {
		background-color: #fff;

		.banner {
			width: 100%;
		}

		.treatment {
			width: 100%;
			height: 930px;

			.treatment-list {
				width: 100%;
				height: 566px;
				display: flex;
				flex-direction: column;
				margin-top: 78px;
				flex-wrap: wrap;
				justify-content: space-between;

				.treatment-item {
					width: 272px;
					border-radius: 2px;
					background-color: #f5f9fc;
					position: relative;
					overflow: hidden;

					.item-title {
						padding: 20px 0 0 24px;
						text-align: left;

						h4 {
							height: 28px;
							margin-bottom: 24px;
							font-size: 20px;
							color: #2b2f33;
							line-height: 28px;
						}

						p {
							height: 22px;
							margin-top: 12px;
							font-size: 14px;
							color: #879099;
							line-height: 22px;
							cursor: pointer;
						}
					}

					&:hover {
						background: linear-gradient(180deg, #0057ad 0%, #99ccff 100%);
					}

					&:hover h4 {
						color: #fff !important;
					}

					&:hover p {
						color: #fff !important;
					}

					&:hover .item-img {
						transform: scale(1.2);
					}
				}

				.item3H {
					height: 566px;
				}

				.item2H {
					height: 372px;
				}

				.item1H {
					height: 178px;
				}

				.item-img {
					position: absolute;
					bottom: 0px;
					width: 100px;
					height: 100px;
					right: 30px;
					transition: transform 0.3s;
				}
			}
		}

		.source {
			width: 100%;
			background: url('../assets/img/sourcebg.png');
			background-size: 100%;
			opacity: 1;

			.source-list {
				padding: 34px 0 70px;

				.banner-item {
					width: 80%;
					margin: auto;
					align-items: flex-start;

					.banner-img {
						height: 361px;
						margin-top: 54px;
					}

					.banner-title {
						width: 442px;

						h2 {
							font-size: 200px;
							font-weight: 600;
							color: rgba(19, 52, 75, 0.76);
						}

						p {
							font-size: 20px;
							font-weight: 400;
							color: rgba(19, 52, 75, 0.9);
							text-align: left;
							line-height: 28px;
						}
					}
				}

				.source-scale {
					width: 100%;
					padding-top: 100px;
				}

				.source-year {
					width: 1238px;
					position: relative;
					margin: auto;
					padding-top: 30px;
					align-items: center;
					font-size: 24px;
					font-weight: 600;
					color: rgba(19, 52, 75, 0.4);
					line-height: 33px;

					.source-img {
						position: absolute;
						top: -42px;
						width: 13px;
						height: 39px;
					}
				}
			}
		}

		.carHeight {
			height: 415px;

			/deep/ .el-carousel__container {
				height: 400px;
			}
		}

		// .brand {
		//   width: 100%;

		//   .brand-list {
		//     align-items: center;
		//     padding: 88px 0;

		//     .box-card {
		//       position: absolute;
		//       top: 148px;
		//       width: 30%;
		//       padding: 42px 49px 49px;
		//       background: linear-gradient(163deg, #E4F0FF 0%, #FFFFFF 100%);
		//       box-shadow: 0px 10px 30px 0px rgba(233, 242, 249, 0.78);
		//       border-radius: 8px;

		//       .brand-title {
		//         width: 100%;
		//         height: 108px;
		//         flex-direction: column;
		//         align-items: flex-start;
		//         color: #333333;

		//         .brand-img1 {
		//           width: 94px;
		//           height: 62px;
		//         }

		//         .brand-img2 {
		//           width: 57px;
		//           height: 60px;
		//         }

		//         .brand-img3 {
		//           width: 59px;
		//           height: 59px;
		//         }

		//         .brand-tit {
		//           font-size: 24px;
		//           font-weight: 500;
		//           line-height: 33px;
		//         }
		//       }

		//       .brand-body {
		//         height: 165px;
		//         padding: 43px 32px 0 0;
		//         text-align: left;
		//         font-size: 16px;
		//         line-height: 28px;
		//       }

		//       .bg-icon {
		//         width: 100%;
		//         height: 70px;
		//         display: flex;
		//         justify-content: flex-end;
		//         align-items: center;

		//         img {
		//           width: 30px;
		//           height: 49px;
		//         }
		//       }

		//     }
		//   }
		// }

		.users {
			width: 100%;
			background: #f5f7fd;

			.users-box {
				position: relative;

				.users-bg {
					position: absolute;
					right: -106px;
					bottom: 0;
					width: 968px;
					height: 441px;
				}
			}

			.users-list {
				position: relative;
				padding: 84px 0 53px;
				z-index: 10;

				.users-body {
					width: 588px;
					padding-bottom: 67px;
					text-align: left;
					font-size: 18px;
					font-weight: 400;
					color: #333333;

					.users-title {
						line-height: 50px;
					}

					.usersNum {
						font-size: 36px;
						color: #206db0;
					}

					.users-info {
						color: #5b5b5b;
						line-height: 25px;
					}
				}
			}
		}

		.contact {
			width: 100%;

			.contact-list {
				padding: 79px 0 108px;

				.contact-address {
					.address-img {
						width: 521px;
						height: 286px;
					}

					.address-info {
						width: 531px;

						.left {
							padding-top: 17px;
							text-align: left;

							.left-body {
								height: 20px;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								margin-top: 10px;
								font-size: 14px;
								font-weight: 400;
								color: #323232;
								line-height: 20px;
							}

							.left-img {
								width: 15px;
								margin-right: 9px;
							}
						}

						.right {
							padding-top: 29px;

							.right-code {
								width: 117px;
								height: 117px;
							}

							p {
								width: 117px;
								font-size: 12px;
								font-weight: 500;
								color: rgba(19, 52, 75, 0.67);
								line-height: 17px;
							}
						}
					}
				}

				.contact-map {
					width: 627px;
					height: 425px;
				}
			}
		}

		.home-box {
			width: 1420px;
			margin: auto;

			.home-title {
				width: 100%;
				padding: 88px 0 12px;
				text-align: center;
				font-size: 56px;
				font-weight: 400;
				color: #13344b;
				line-height: 64px;
			}

			.home-auxiliary {
				width: 100%;
				text-align: center;
				font-size: 28px;
				font-weight: 400;
				color: #4f5e6a;
				line-height: 36px;
			}
		}
	}

	img {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 1550px) {
		.home {
			.treatment {
				height: 786px;

				.treatment-list {
					height: 478px;
					margin-top: 66px;

					.treatment-item {
						width: 230px;

						.item-title {
							padding: 17px 0 0 18px;

							h4 {
								margin-bottom: 18px;
								font-size: 17px;
								line-height: 28px;
							}

							p {
								margin-top: 8px;
							}
						}
					}

					.item3H {
						height: 478px;
					}

					.item2H {
						height: 314px;
					}

					.item1H {
						height: 150px;
					}

					.item-img {
						width: 80px;
						height: 80px;
						right: 20px;
					}
				}
			}

			.source {
				.source-list {
					padding: 29px 0 59px;

					.banner-item {
						.banner-img {
							height: 305px;
							margin-top: 46px;
						}

						.banner-title {
							width: 374px;

							h2 {
								font-size: 169px;
							}

							p {
								font-size: 17px;
								line-height: 24px;
							}
						}
					}

					.source-scale {
						padding-top: 20px;
					}

					.source-year {
						width: 1046px;
						font-size: 20px;
						line-height: 28px;
					}
				}
			}

			// .brand {
			//   .brand-list {
			//     padding: 74px 0;

			//     .box-card {
			//       padding: 35px 41px 41px;

			//       .brand-body {
			//         padding: 36px 27px 0 0;
			//       }

			//       .bg-icon {
			//         height: 59px;
			//       }
			//     }
			//   }
			// }

			.users {
				.users-box {
					.users-bg {
						right: -90px;
						width: 818px;
						height: 373px;
					}
				}

				.users-list {
					padding: 71px 0 45px;

					.users-body {
						width: 497px;
						padding-bottom: 57px;
						font-size: 15px;

						.users-title {
							line-height: 42px;
						}

						.usersNum {
							font-size: 30px;
						}
					}
				}
			}

			.contact {
				.contact-list {
					padding: 67px 0 91px;

					.contact-address {
						.address-img {
							width: 440px;
							height: 242px;
						}

						.address-info {
							width: 449px;
						}
					}

					.contact-map {
						width: 530px;
						height: 359px;
					}
				}
			}

			.home-box {
				width: 1200px;

				.home-title {
					font-size: 48px;
					line-height: 54px;
				}

				.home-auxiliary {
					font-size: 24px;
					line-height: 30px;
				}
			}
		}
	}

	@media screen and (max-width: 750px) {
		.home-box {
			width: 100% !important;

			.home-title {
				font-size: 28px !important;
				line-height: 54px;
			}

			.home-auxiliary {
				font-size: 24px;
				line-height: 30px;
			}
		}

		.mid-text {
			display: none !important;
		}

		.contact-address {
			width: 100vw;

			.address-info {
				width: 100% !important;
			}
		}

		.users-body {
			width: 100% !important;
		}

		.source-year {
			width: 100% !important;
			font-size: 10px !important;
			line-height: 18px !important;
		}

		.carHeight {
			height: 315px !important;

			/deep/ .el-carousel__container {
				height: 300px !important;
			}
		}
		.source-scale {
			padding-top: 1px !important;
		}
		.source-img {
			display: none;
		}

		.treatment {
			display: none !important;
			height: 200px !important;

			.treatment-list {
				display: none !important;
			}
		}

		.home {
			.source {
				#rollBox2 {
					padding: 29px 0 59px;

					.banner-item {
						width: 100% !important;
						.banner-img {
							display: none !important;
						}

						.banner-title {
							width: 90vw !important;
							margin: 0 auto !important;
							h2 {
								font-size: 100px !important;
							}
						}
					}
				}
			}
		}
	}
</style>
